import React from 'react'
import Layout from '../components/Layout'
import { Wrapper } from '../components/Wrapper'
import BlogData from '../components/Blog/BlogData'
import { Row, Col } from '../components/Layout/Grid'
import Img from '../components/Img'
import styled from 'styled-components'
import { media } from '../components/Utilities/Media'
import { colorDarkGray } from '../components/Variables/Variables'
import { Link } from 'gatsby'

const BlogStyle = styled(Wrapper)`
   & {
       .blog-img {
           overflow: hidden;
       }
       img {
           width: 100%;
           height: 318px;
           object-fit: cover;
           &:hover {
               transform: scale(1.05);
           }

       }
       .blog-text {
           padding: 20px;
       }
   }
`

const BlogRow = styled(Row)`
   align-items: initial;
   margin-left: -34px;
   padding-top: 50px;
`

const BlogCol = styled(Col)`
   @media ${media.md} {
       width: 33.33%;
       padding: 0 0 50px 34px;
   }
   @media ${media.lg} {
       width: 25%;
   }
    & > div {
        box-shadow: 0 2px 4px 0 hsla(0,0%,68%,.32);
    }

    h4 {
        display: inline;
    }
    span {
        text-align: right;
        display: block;
        color: ${colorDarkGray};
    }
`

const BlogPage = () => {
    function renderBlogs() {
        let data = BlogData();
        let blogs = data.map(blog => (
           <BlogCol>
               <div>
                    <div className="blog-img">
                        <Link to={`/post/${blog.node.slug}`}><Img src={blog.node.featured_media.source_url} /></Link>
                    </div>
                    <div className="blog-text">
                        <h4>{blog.node.title}</h4>
                        <span>{blog.node.date}</span>
                    </div>
               </div>
           </BlogCol>
        ))
        return blogs;
    }
    return (
        <Layout>
            <BlogStyle>
                <BlogRow>
                    { renderBlogs() }
                </BlogRow>
            </BlogStyle>
        </Layout>
    ) 
}

export default BlogPage
